@import "@progress/kendo-theme-default/scss/chip/_theme.scss";

@mixin kendo-chip--theme() {

    @include kendo-chip--theme-base();

    @each $name, $color in $kendo-chip-theme-colors {
        .k-chip-outline-#{$name} {

            @if ($name == "info") {
                @include fill (
                    $color: if($kendo-enable-color-system, $kendo-chip-outline-text, if( $kendo-is-dark-theme, k-color-tint($color, 25%), $kendo-chip-outline-text)),
                    $border: if($kendo-enable-color-system, $color, if( $kendo-is-dark-theme, k-color-tint($color, 25%), $color))
                );

                &:hover,
                &.k-hover,
                &.k-selected {
                    @include fill(
                        if($kendo-enable-color-system, $kendo-chip-outline-text, if( $kendo-is-dark-theme, k-contrast-color($color), $kendo-chip-outline-text)),
                        $color,
                        $color
                    );
                }

            }
        }
    }

}
