html,
body {
  font-size: $base-font-size !important;
  min-height: 100vh;
  height: auto;
}

.container-full-width {
  width: 100% !important;
}

.space,
.space5 {
  height: 5px !important;
}

.space7 {
  height: 7px !important;
}

.space10 {
  height: 10px !important;
}

.space15 {
  height: 15px !important;
}

.space20 {
  height: 20px !important;
}

.space25 {
  height: 25px !important;
}

.space30 {
  height: 30px !important;
}

.required {
  color: #ff0000 !important;
}

.error-message {
  color: #ff0000 !important;
  font-size: 10px !important;
}

.warning-message {
  color: #fd7e14 !important;
  font-size: 10px !important;
}

.page_full_height {
  min-height: 750px !important;
}

.cdk-overlay-container {
  z-index: 10001 !important;
}

.no-top-border {
  border-top: 0px !important;
}

.no-left-border {
  border-left: 0px !important;
}

.no-right-border {
  border-right: 0px !important;
}

.no-bottom-border {
  border-right: 0px !important;
}

.no-border {
  border: 0px !important;
}

.grid-no-left-border .k-grid {
  border-left-width: 0px !important;
}

.grid-no-right-border .k-grid {
  border-right-width: 0px !important;
}
.grid-no-top-border .k-grid {
  border-top-width: 0px !important;
}
.grid-no-bottom-border .k-grid {
  border-bottom-width: 0px !important;
}

.card {
  border-radius: 0px !important;
  box-shadow: none !important;
  padding: 0px !important;
}

td .no-wrap {
  white-space: nowrap;
}

.sidenav-panel {
  width: 100% !important;
  min-width: $base-page-min-width;
  padding-left: calc(var(--bs-gutter-x) * 0) !important;
  padding-right: 0px !important;
}

.panel-small {
  width: $base-side-panel-small-width !important;
  min-height: $base-page-min-height !important;
  height: 100% !important;
}

.panel-medium {
  width: $base-side-panel-medium-width !important;
  min-height: $base-page-min-height !important;
  height: 100% !important;
}

.panel-large {
  width: $base-side-panel-large-width !important;
  min-height: $base-page-min-height !important;
  height: 100% !important;
}

.panel-xlarge {
  width: $base-side-panel-xlarge-width !important;
  min-height: $base-page-min-height !important;
  height: 100% !important;
}

.panel-xsmall {
  width: $base-side-panel-xsmall-width !important;
  min-height: $base-page-min-height !important;
  height: 100% !important;
}

.panel-full {
  width: $base-side-panel-full-width !important;
  min-height: $base-page-full-height !important;
  height: 100% !important;
}

.panel-width-small {
  width: $base-side-panel-small-width !important;
}

.panel-width-medium {
  width: $base-side-panel-medium-width !important;
}

.right-float {
  float: right;
}

.no-padding {
  padding: 0px !important;
}

.no-padding-bottom {
  padding-bottom: 0px !important;
}

.k-window {
  z-index: 1001 !important;
}

.k-overlay {
  z-index: 1000 !important;
}

//information heading above the grid
.panel-heading {
  color: $gray-700 !important;
  border-radius: 12px;
  background-color: $gray-300;
  padding-left: 5px !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  text-decoration: none;
  margin: 4px 4px;
}

.div-slide-panel {
  padding: 0px !important;
  margin: 0px !important;
  min-height: $base-page-min-height !important;
  width: 100% !important;
}

.right-padding2 {
  padding-right: 2px !important;
}

.right-padding5 {
  padding-right: 5px !important;
}

.right-padding10 {
  padding-right: 10px !important;
}

.right-padding15 {
  padding-right: 15px !important;
}

.right-padding20 {
  padding-right: 20px !important;
}

.right-padding25 {
  padding-right: 25px !important;
}

.left-padding10 {
  padding-left: 10px !important;
}

.left-padding15 {
  padding-left: 15px !important;
}

.left-padding20 {
  padding-left: 20px !important;
}

.left-padding25 {
  padding-left: 25px !important;
}

.top-padding2 {
  padding-top: 2px !important;
}

.top-padding5 {
  padding-top: 5px !important;
}

.top-padding10 {
  padding-top: 10px !important;
}

.top-padding15 {
  padding-top: 15px !important;
}

.edit-form-placeholder {
  border: 2px solid #ffffff !important;
}

.loading-panel {
  z-index: 1002 !important;
  position: absolute !important;
  top: 50%;
  left: 50%;
}
.padding5 {
  padding: 5px !important;
}

.padding10 {
  padding: 10px !important;
}

.padding20 {
  padding: 20px !important;
}

.hide {
  display: none !important;
  visibility: hidden !important;
}

.invalid {
  background-color: #ff0000 !important;
}

.yellowWarning {
  background-color: #ffff9f !important;
}

.systemDetail {
  padding: 0.3rem !important;
}

.header-text {
  font-size: $base-grid-header-font-size !important;
  font-weight: $base-header-font-weight !important;
}

.k-editor-content {
  border-left: 1px solid $gray-300 !important;
  border-right: 1px solid $gray-300 !important;
  border-bottom: 1px solid $gray-300 !important;
}

.k-textarea {
  border: 1px solid $gray-300 !important;
}

//set the max height of kendo dialog windows to 90% of the viewport height
.k-window {
  max-height: 90vh !important;
}

//detail row content display
.k-detail-row .row > * {
  width: auto !important;
}

.flex-container {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.float-right {
  float: right !important;
}

.float-left {
  float: left !important;
}

.entry-row {
  margin: 12px 0 12px 0 !important;
}

.lp-footer {
  display: none !important;
}

.text-wrap {
  word-break: break-all;
  white-space: normal;
  overflow-wrap: break-word;
}

.div-wrap {
  max-width: 100%; /* prevent the div from expanding beyond its parent */
  overflow: hidden; /* hide overflowing content */
  word-wrap: break-word; /* force long words to wrap to the next line */
}

.lpx-breadcrumb-container {
  width: 100% !important;
}

.breadcrumb {
  padding-bottom: 0px !important;
  background-color: transparent !important;
  border: 0px !important;
  border-radius: 0px !important;
}

.lpx-content .full_page_height {
  min-height: $base-page-full-height !important;
}

//breadcrumb
li.breadcrumb-item.active {
  font-weight: bolder !important;
}

.div-display-none {
  display: none !important;
}

.k-context-menu {
  z-index: 10000 !important;
}

.lpx-content {
  margin-top: 0.5rem !important;
}
