@import "@progress/kendo-theme-default/scss/pager/_layout.scss";

@mixin kendo-pager--layout() {

    @include kendo-pager--layout-base();

    // Pager items
    .k-pager-nav { @include border-radius( 0 ); }
    .k-pager-first { @include border-left-radius-only( $kendo-pager-item-border-radius ); }
    .k-pager-last { @include border-right-radius-only( $kendo-pager-item-border-radius ); }

    .k-rtl,
    [dir="rtl"] {
        .k-pager-first { @include border-right-radius-only( $kendo-pager-item-border-radius ); }
        .k-pager-last { @include border-left-radius-only( $kendo-pager-item-border-radius ); }
    }

}
