.order-activated-in-area {
  background-color: #aeff7c !important;
}

.order-available-in-area {
  background-color: #46b600 !important;
}

.order-activated-other-area {
  background-color: #ff9956 !important;
}

.order-accepted-by-this-area {
  background-color: #fcc39d !important;
}

.owned-by {
  color: #1616fd !important;
}

.test-mode {
  color: #f47d2f !important;
}

.connected,
.open {
  color: #00ff00 !important;
}

.connecting,
.processing {
  color: #1616fd !important;
}

.reconnecting {
  color: #f47d2f !important;
}

.disconnected,
.suspended {
  color: #ff0000 !important;
}

.watchlist-highlight {
  background-color: #ffffb0 !important;
}

.incident-alert-highlight {
  background-color: #f9e2d2 !important;
}

.peak {
  color: #b60202 !important;
}

.standard {
  color: #b15601 !important;
}

.off-peak {
  color: #00852a !important;
}
