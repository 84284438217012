@import "@progress/kendo-theme-default/scss/forms/_layout.scss";

@mixin kendo-form--layout() {

    @include kendo-form--layout-base();

    // Forms
    .k-form,
    .k-form-inline {
        legend {
            font-size: $kendo-font-size-md * 1.5;
            font-weight: normal;
            line-height: $kendo-line-height-md;
            text-transform: none;
        }

        .k-field-info {
            opacity: .7;
        }
    }

    .k-form {
        legend {
            margin-bottom: $kendo-padding-md-y;
        }

        .k-field-info {
            margin-block: 0;
            margin-inline: k-math-div( $kendo-padding-md-x, 2 );
        }
    }

    .k-form-inline {
        legend {
            margin-bottom: 0;
        }

        .k-form-field {
            > span:not(.k-widget) {
                padding: calc( #{$kendo-padding-md-y} - #{$kendo-input-border-width} ) 0;
                padding-right: $kendo-padding-md-x;
            }
        }
    }

    .k-edit-label {
        padding: calc( #{$kendo-padding-md-y} - #{$kendo-input-border-width} ) 0;
    }

}
