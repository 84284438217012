@import "@progress/kendo-theme-default/scss/popup/_layout.scss";

@mixin kendo-popup--layout() {

    @include kendo-popup--layout-base();

    .k-popup.k-column-menu {
        padding-top: 0;
        padding-bottom: 0;
    }

}
