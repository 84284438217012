// Menu
$kendo-menu-border-width: 0px !default;
$kendo-menu-font-family: $kendo-font-family !default;
$kendo-menu-font-size: $kendo-font-size-md !default;
$kendo-menu-line-height: $kendo-line-height-md !default;

$kendo-menu-bg: $kendo-component-header-bg !default;
$kendo-menu-text: $kendo-component-header-text !default;
$kendo-menu-border: $kendo-component-header-border !default;
$kendo-menu-gradient: null !default;

$kendo-menu-item-padding-x: k-map-get( $kendo-spacing, 2 ) !default;
$kendo-menu-item-padding-y: k-map-get( $kendo-spacing, 2 ) !default;
$kendo-menu-item-spacing: 0 !default;
$kendo-menu-item-icon-spacing: $kendo-icon-spacing !default;

$kendo-menu-item-bg: null !default;
$kendo-menu-item-text: if($kendo-enable-color-system, rgba( k-color( on-app-surface, true ), .5 ), rgba( $kendo-menu-text, .5 )) !default;
$kendo-menu-item-border: null !default;
$kendo-menu-item-gradient: null !default;

$kendo-menu-item-hover-bg: null !default;
$kendo-menu-item-hover-text: if($kendo-enable-color-system, rgba( k-color( on-app-surface, true ), .7 ), rgba( $kendo-menu-text, .7 )) !default;
$kendo-menu-item-hover-border: null !default;
$kendo-menu-item-hover-gradient: null !default;

$kendo-menu-item-expanded-bg: null !default;
$kendo-menu-item-expanded-text: if($kendo-enable-color-system, rgba( k-color( on-app-surface, true ), .9 ), rgba( $kendo-menu-text, .9 )) !default;
$kendo-menu-item-expanded-border: null !default;
$kendo-menu-item-expanded-gradient: null !default;

$kendo-menu-item-focus-shadow: inset 0 0 0 3px if($kendo-enable-color-system, rgba( k-color( on-app-surface, true ), .15 ), rgba( $kendo-menu-text, .15 )) !default;

$kendo-menu-separator-spacing: k-map-get( $kendo-spacing, 1 ) !default;

$kendo-menu-scroll-button-bg: $kendo-menu-bg !default;
$kendo-menu-scroll-button-text: if($kendo-enable-color-system, rgba( k-color( on-app-surface, true ), .5 ), rgba( $kendo-menu-text, .5 )) !default;
$kendo-menu-scroll-button-border: $kendo-menu-border !default;
$kendo-menu-scroll-button-gradient: null !default;

$kendo-menu-scroll-button-hover-bg: null !default;
$kendo-menu-scroll-button-hover-text: if($kendo-enable-color-system, rgba( k-color( on-app-surface, true ), .7 ), rgba( $kendo-menu-text, .7 )) !default;
$kendo-menu-scroll-button-hover-border: null !default;
$kendo-menu-scroll-button-hover-gradient: null !default;


// Menu popup

/// Horizontal padding of the menu popup.
/// @group menu
$kendo-menu-popup-padding-x: null !default;

/// Vertical padding of the menu popup.
/// @group menu
$kendo-menu-popup-padding-y: null !default;

/// Width of the border around the menu popup.
/// @group menu
$kendo-menu-popup-border-width: $kendo-popup-border-width !default;

/// Font sizes of the menu popup.
/// @group menu
$kendo-menu-popup-font-size: $kendo-font-size-md !default;
$kendo-menu-popup-sm-font-size: $kendo-font-size-md !default;
$kendo-menu-popup-md-font-size: $kendo-font-size-md !default;
$kendo-menu-popup-lg-font-size: $kendo-font-size-lg !default;

/// Line heights used along with $kendo-font-size.
/// @group menu
$kendo-menu-popup-line-height: $kendo-line-height-md !default;
$kendo-menu-popup-sm-line-height: $kendo-line-height-md !default;
$kendo-menu-popup-md-line-height: $kendo-line-height-md !default;
$kendo-menu-popup-lg-line-height: $kendo-line-height-md !default;

/// The background of the menu popup.
/// @group menu
$kendo-menu-popup-bg: $kendo-popup-bg !default;
/// The text color of the menu popup.
/// @group menu
$kendo-menu-popup-text: $kendo-popup-text !default;
/// The border color of the menu popup.
/// @group menu
$kendo-menu-popup-border: $kendo-popup-border !default;
/// The background gradient of the menu popup.
/// @group menu
$kendo-menu-popup-gradient: null !default;


// Menu popup item

/// Horizontal padding of the menu item in popup.
/// @group menu
$kendo-menu-popup-item-padding-x: k-map-get( $kendo-spacing, 4 ) !default;
$kendo-menu-popup-sm-item-padding-x: k-map-get( $kendo-spacing, 4 ) !default;
$kendo-menu-popup-md-item-padding-x: k-map-get( $kendo-spacing, 4 ) !default;
$kendo-menu-popup-lg-item-padding-x: k-map-get( $kendo-spacing, 4 ) !default;

/// Vertical padding of the menu item in popup.
/// @group menu
$kendo-menu-popup-item-padding-y: k-map-get( $kendo-spacing, 1 ) !default;
$kendo-menu-popup-sm-item-padding-y: k-map-get( $kendo-spacing, 0.5 ) !default;
$kendo-menu-popup-md-item-padding-y: k-map-get( $kendo-spacing, 1 ) !default;
$kendo-menu-popup-lg-item-padding-y: k-map-get( $kendo-spacing, 1.5 ) !default;

/// The end padding of the menu item in popup.
/// @group menu
$kendo-menu-popup-item-padding-end: calc( #{$kendo-menu-popup-item-padding-x * 2} + #{$kendo-icon-size} ) !default;
$kendo-menu-popup-sm-item-padding-end: calc( #{$kendo-menu-popup-sm-item-padding-x * 2} + #{$kendo-icon-size} ) !default;
$kendo-menu-popup-md-item-padding-end: calc( #{$kendo-menu-popup-md-item-padding-x * 2} + #{$kendo-icon-size} ) !default;
$kendo-menu-popup-lg-item-padding-end: calc( #{$kendo-menu-popup-lg-item-padding-x * 2} + #{$kendo-icon-size} ) !default;

/// The start margin of the menu item expand icon.
/// @group menu
$kendo-menu-popup-sm-item-icon-margin-start: $kendo-menu-popup-sm-item-padding-x !default;
$kendo-menu-popup-md-item-icon-margin-start: $kendo-menu-popup-md-item-padding-x !default;
$kendo-menu-popup-lg-item-icon-margin-start: $kendo-menu-popup-lg-item-padding-x !default;

/// The end margin of the menu item expand icon.
/// @group menu
$kendo-menu-popup-sm-item-icon-margin-end: calc( -1 * (#{$kendo-menu-popup-sm-item-padding-end} - #{k-math-div( $kendo-menu-popup-sm-item-padding-x, 2 )}) ) !default;
$kendo-menu-popup-md-item-icon-margin-end: calc( -1 * (#{$kendo-menu-popup-md-item-padding-end} - #{k-math-div( $kendo-menu-popup-md-item-padding-x, 2 )}) ) !default;
$kendo-menu-popup-lg-item-icon-margin-end: calc( -1 * (#{$kendo-menu-popup-lg-item-padding-end} - #{k-math-div( $kendo-menu-popup-lg-item-padding-x, 2 )}) ) !default;

/// The spacing between the menu items in popup.
/// @group menu
$kendo-menu-popup-item-spacing: 0px !default;

/// The background of the menu item in popup.
/// @group menu
$kendo-menu-popup-item-bg: null !default;
/// The text color of the menu item in popup.
/// @group menu
$kendo-menu-popup-item-text: null !default;
/// The border color of the menu item in popup.
/// @group menu
$kendo-menu-popup-item-border: null !default;
/// The background gradient of the menu item in popup.
/// @group menu
$kendo-menu-popup-item-gradient: null !default;

/// The background of hovered menu item in popup.
/// @group menu
$kendo-menu-popup-item-hover-bg: $kendo-list-item-hover-bg !default;
/// The text color of hovered menu item in popup.
/// @group menu
$kendo-menu-popup-item-hover-text: $kendo-list-item-hover-text !default;
/// The border color of hovered menu item in popup.
/// @group menu
$kendo-menu-popup-item-hover-border: null !default;
/// The background gradient of hovered menu item in popup.
/// @group menu
$kendo-menu-popup-item-hover-gradient: null !default;

/// The background of expanded menu item in popup.
/// @group menu
$kendo-menu-popup-item-expanded-bg: $kendo-list-item-selected-bg !default;
/// The text color of expanded menu item in popup.
/// @group menu
$kendo-menu-popup-item-expanded-text: $kendo-list-item-selected-text !default;
/// The border color of expanded menu item in popup.
/// @group menu
$kendo-menu-popup-item-expanded-border: null !default;
/// The background gradient of expanded menu item in popup.
/// @group menu
$kendo-menu-popup-item-expanded-gradient: null !default;

/// The base shadow of focused menu item in popup.
/// @group menu
$kendo-menu-popup-item-focus-shadow: $kendo-menu-item-focus-shadow !default;
