// Scheduler
$kendo-scheduler-border-width: 1px !default;
$kendo-scheduler-font-family: $kendo-font-family !default;
$kendo-scheduler-font-size: $kendo-font-size-md !default;
$kendo-scheduler-line-height: $kendo-line-height-md !default;

$kendo-scheduler-bg: $kendo-component-bg !default;
$kendo-scheduler-text: $kendo-component-text !default;
$kendo-scheduler-border: $kendo-component-border !default;

$kendo-scheduler-toolbar-bg: $kendo-toolbar-bg !default;
$kendo-scheduler-toolbar-text: $kendo-toolbar-text !default;
$kendo-scheduler-toolbar-border: $kendo-toolbar-border !default;
$kendo-scheduler-toolbar-gradient: $kendo-toolbar-gradient !default;

$kendo-scheduler-footer-bg: $kendo-toolbar-bg !default;
$kendo-scheduler-footer-text: $kendo-toolbar-text !default;
$kendo-scheduler-footer-border: $kendo-toolbar-border !default;
$kendo-scheduler-footer-gradient: $kendo-toolbar-gradient !default;

$kendo-scheduler-event-min-height: 25px !default;
$kendo-scheduler-event-border-radius: $kendo-border-radius-md !default;
$kendo-scheduler-event-line-height: calc( #{$kendo-scheduler-event-min-height} - (2 * #{$kendo-padding-sm-y}) ) !default;

$kendo-scheduler-event-bg: if($kendo-enable-color-system, k-color( primary ), k-color-tint( $kendo-selected-bg, 2 )) !default;
$kendo-scheduler-event-text: $kendo-selected-text !default;
$kendo-scheduler-event-border: null !default;
$kendo-scheduler-event-gradient: null !default;
$kendo-scheduler-event-shadow: null !default;

$kendo-scheduler-event-hover-bg: null !default;
$kendo-scheduler-event-hover-text: null !default;
$kendo-scheduler-event-hover-border: null !default;
$kendo-scheduler-event-hover-gradient: null !default;
$kendo-scheduler-event-hover-shadow: null !default;

$kendo-scheduler-event-selected-bg: $kendo-selected-bg !default;
$kendo-scheduler-event-selected-text: $kendo-selected-text !default;
$kendo-scheduler-event-selected-border: null !default;
$kendo-scheduler-event-selected-gradient: null !default;
$kendo-scheduler-event-selected-shadow: inset 0 0 0 3px rgba( white, .5 ) !default;

$kendo-scheduler-event-ongoing-shadow: inset 0px 0px 0px 1px #ff0000 !default;

$kendo-scheduler-cell-padding-x: k-map-get( $kendo-spacing, 3 ) !default;
$kendo-scheduler-cell-padding-y: k-map-get( $kendo-spacing, 3 ) !default;
$kendo-scheduler-cell-height: $kendo-line-height-em !default;
$kendo-scheduler-datecolumn-width: 12em !default;
$kendo-scheduler-timecolumn-width: 11em !default;

$kendo-scheduler-nonwork-bg: if($kendo-enable-color-system, k-color( base-subtle ), k-try-shade( $kendo-scheduler-bg, .5 )) !default;
$kendo-scheduler-nonwork-text: null !default;

$kendo-scheduler-weekend-bg: null !default;
$kendo-scheduler-weekend-text: null !default;

$kendo-scheduler-othermonth-bg: if($kendo-enable-color-system, k-color( base-subtle ), k-try-shade( $kendo-scheduler-bg, .5 )) !default;
$kendo-scheduler-othermonth-text: null !default;

$kendo-scheduler-yearview-padding-x: k-map-get( $kendo-spacing, 4 ) !default;
$kendo-scheduler-yearview-padding-y: $kendo-scheduler-yearview-padding-x !default;

$kendo-scheduler-yearview-calendar-gap: k-map-get( $kendo-spacing, 4 ) !default;

$kendo-scheduler-yearview-indicator-size: 3px !default;
$kendo-scheduler-yearview-indicator-calc-offset-top: calc( #{$kendo-calendar-cell-size} - (#{$kendo-calendar-cell-padding-y} * 2)) !default;
$kendo-scheduler-yearview-indicator-calc-offset-left: calc( 50% - #{k-math-div( $kendo-scheduler-yearview-indicator-size, 2)} ) !default;
$kendo-scheduler-yearview-indicator-border-radius: 50% !default;
$kendo-scheduler-yearview-indicator-bg: $kendo-color-primary !default;
$kendo-scheduler-yearview-indicator-selected-bg: $kendo-color-primary-contrast !default;

$kendo-scheduler-tooltip-padding-x: k-map-get( $kendo-spacing, 3 ) !default;
$kendo-scheduler-tooltip-padding-y: k-map-get( $kendo-spacing, 3 ) !default;
$kendo-scheduler-tooltip-border-width: 0 !default;
$kendo-scheduler-tooltip-bg: $kendo-color-primary-contrast !default;
$kendo-scheduler-tooltip-text: $kendo-base-text !default;
$kendo-scheduler-tooltip-border: null !default;
$kendo-scheduler-tooltip-shadow: k-elevation(2) !default;

$kendo-scheduler-tooltip-title-margin-y: k-map-get( $kendo-spacing, 3 ) !default;
$kendo-scheduler-tooltip-month-font-size: $kendo-font-size-sm !default;
$kendo-scheduler-tooltip-day-font-size: $kendo-scheduler-tooltip-month-font-size * 2 !default;

$kendo-scheduler-tooltip-events-max-height: 250px !default;
$kendo-scheduler-tooltip-events-gap: k-map-get( $kendo-spacing, 2 ) !default;

$kendo-scheduler-tooltip-event-padding-x: k-map-get( $kendo-spacing, 2 ) !default;
$kendo-scheduler-tooltip-event-padding-y: k-map-get( $kendo-spacing, 1 ) !default;
$kendo-scheduler-tooltip-event-border-radius: $kendo-border-radius-md !default;
$kendo-scheduler-tooltip-event-gap: k-map-get( $kendo-spacing, 2 ) !default;

$kendo-scheduler-tooltip-callout-text: $kendo-scheduler-tooltip-bg !default;
