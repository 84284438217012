.handsontable {
  touch-action: manipulation;
  font-size: 12px;
  font-weight: normal;
}

.handsontable table thead th {
  white-space: pre-line;
  max-width: 100px; //Wrap column header text when it exceeds 100px
}

.handsontable th {
  background-color: $headerColor !important;
}

.handsontable thead th.ht__highlight {
  background-color: $headerHighlightColor !important;
}

.handsontable .htDimmed {
  background-color: $readColor !important;
  color: #000000;
}

.handsontable .htReadonly {
  background-color: $readColor !important;
  color: #000000;
}

.handsontable .htFunction {
  background-color: $headerColor !important;
  color: #000000;
}

.handsontable .htFirstCol {
  background-color: $headerColor !important;
  color: #000000;
  text-align: left;
}

.handsontable .htInfoCol1 {
  background-color: $infoColor !important;
  color: $infoText !important;
}
