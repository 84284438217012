// Please see
// https://www.telerik.com/kendo-angular-ui/components/styling/theme-bootstrap/customization-common/
// for variables available to override for the kendo ui grid.
// Please also see the file located in
// node_modules\@progress\kendo-theme-bootstrap\scss\_variables.scss
// for all other variables available to override
.k-container-full {
  width: 98% !important;
  font-size: $base-header-font-size !important;
  font-weight: $base-header-font-weight !important;
}

.k-input-full {
  width: 100% !important;
  font-size: $base-font-size !important;
  white-space: nowrap !important;
  border-radius: $default-border-radius !important;
  border-color: $borderColor !important;
}

.k-dropdown-full {
  font-size: $base-font-size !important;
  white-space: nowrap !important;
}

.k-input,
.k-dropdown-wrap,
.k-searchbar,
.k-animation-container,
.k-textbox,
.k-button {
  font-size: $base-font-size !important;
}

.padded-input {
  padding-top: $base-medium-input-padding !important;
  padding-bottom: $base-medium-input-padding !important;
}
td .no-wrap {
  white-space: nowrap;
}

.k-label {
  font-size: $base-header-font-size !important;
  font-weight: $base-header-font-weight !important;
}

.padding-top2 {
  padding-top: 2px !important;
}

.padding-top3 {
  padding-top: 3px !important;
}

.padding-top5 {
  padding-top: 5px !important;
}

.vertical-align-bottom {
  vertical-align: bottom !important;
}

.vertical-align-middle {
  vertical-align: middle !important;
}

.k-datetime-container .k-datetime-wrap {
  width: 288px !important;
  overflow: hidden !important;
}

.list-view-item {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px;
}

.list-view-item-name {
  font-size: 12px;
}

.list-view-item-header {
  font-size: 14px;
  font-weight: bold;
  align-items: center;
  padding: 4px;
}

// Allow for the use of new line characters to format tooltip display strings
.k-tooltip {
  white-space: pre-line !important;
}

.k-switch-off {
  color: #000 !important;
}

.k-calendar-navigation {
  z-index: 1;
}

.k-input-value-text {
  color: $gray-700 !important;
}

.primary-font-color {
  color: $primary-font-color !important;
}
