@import "./core/functions/index.import.scss";

// Options
$kendo-enable-rounded: true !default;
$kendo-enable-shadows: true !default;
$kendo-enable-gradients: true !default;
$kendo-enable-transitions: true !default;
$kendo-enable-focus-contrast: false !default;
$kendo-enable-typography: false !default;

$kendo-use-input-button-width: false !default;
$kendo-use-input-spinner-width: false !default;
$kendo-use-input-spinner-icon-offset: false !default;

$kendo-auto-bootstrap: true !default;


// Spacing
$kendo-spacing: (
    0: 0,
    1px: 1px,
    0.5: .125rem,
    1: .25rem,
    1.5: .375rem,
    2: .5rem,
    2.5: .625rem,
    3: .75rem,
    3.5: .875rem,
    4: 1rem,
    4.5: 1.125rem,
    5: 1.25rem,
    5.5: 1.375rem,
    6: 1.5rem,
    6.5: 1.625rem,
    7: 1.75rem,
    7.5: 1.875rem,
    8: 2rem,
    9: 2.25rem,
    10: 2.5rem,
    11: 2.75rem,
    12: 3rem,
    13: 3.25rem,
    14: 3.5rem,
    15: 3.75rem,
    16: 4rem,
    17: 4.25rem,
    18: 4.5rem,
    19: 4.75rem,
    20: 5rem,
    21: 5.25rem,
    22: 5.5rem,
    23: 5.75rem,
    24: 6rem
) !default;


// Icons
$kendo-icon-size: 16px !default;

$kendo-icon-size-xs: calc( #{$kendo-icon-size} * .75 ) !default;
$kendo-icon-size-sm: calc( #{$kendo-icon-size} * .875 ) !default;
$kendo-icon-size-md: $kendo-icon-size !default;
$kendo-icon-size-lg: calc( #{$kendo-icon-size} * 1.25 ) !default;
$kendo-icon-size-xl: calc( #{$kendo-icon-size} * 1.5 ) !default;
$kendo-icon-size-xxl: calc( #{$kendo-icon-size} * 2 ) !default;
$kendo-icon-size-xxxl: calc( #{$kendo-icon-size} * 3 ) !default;

$kendo-icon-spacing: k-map-get( $kendo-spacing, 2 ) !default;
$kendo-icon-padding: k-map-get( $kendo-spacing, 1 ) !default;

// Metrics
$kendo-padding-x: k-map-get( $kendo-spacing, 4 ) !default;
$kendo-padding-y: k-map-get( $kendo-spacing, 2 ) !default;
$kendo-padding-sm-x: k-map-get( $kendo-spacing, 2 ) !default;
$kendo-padding-sm-y: k-map-get( $kendo-spacing, 1 ) !default;
$kendo-padding-md-x: k-map-get( $kendo-spacing, 4 ) !default;
$kendo-padding-md-y: k-map-get( $kendo-spacing, 2 ) !default;
$kendo-padding-lg-x: k-map-get( $kendo-spacing, 6 ) !default;
$kendo-padding-lg-y: k-map-get( $kendo-spacing, 3 ) !default;

/// Border radius for all components.
$kendo-border-radius: $border-radius !default;
$kendo-border-radius-sm: $border-radius-sm !default;
$kendo-border-radius-md: $kendo-border-radius !default;
$kendo-border-radius-lg: $border-radius-lg !default;

$kendo-border-radii: (
    DEFAULT: $kendo-border-radius-md,
    0: 0,
    sm: $kendo-border-radius-sm,
    md: $kendo-border-radius-md,
    lg: $kendo-border-radius-lg,
    full: 9999px
) !default;


$kendo-zindex-popup: 1 !default;
$kendo-zindex-window: 2 !default;
$kendo-zindex-loading: 100 !default;


// Typography

/// Base font size across all components.
/// @group typography
$kendo-font-size: $font-size-base !default;
$kendo-font-size-xs: ( $kendo-font-size * .75 ) !default;
$kendo-font-size-sm: $font-size-sm !default;
$kendo-font-size-md: $kendo-font-size !default;
$kendo-font-size-lg: $font-size-lg !default;
$kendo-font-size-xl: ( $kendo-font-size-md * 1.5 ) !default;

$kendo-font-sizes: (
    xs: $kendo-font-size-xs,
    sm: $kendo-font-size-sm,
    md: $kendo-font-size-md,
    lg: $kendo-font-size-lg,
    xl: $kendo-font-size-xl
) !default;

/// Font family for text.
/// @group typography
$kendo-font-family-sans-serif: $font-family-sans-serif !default;

/// Font family for monospaced text. Used for styling the code.
/// @group typography
$kendo-font-family-monospace: $font-family-monospace !default;

/// Font family across all components.
/// @group typography
$kendo-font-family: $kendo-font-family-sans-serif !default;

/// Line height used along with $kendo-font-size.
/// @group typography
$kendo-line-height: $line-height-base !default;
$kendo-line-height-xs: 1 !default;
$kendo-line-height-sm: $line-height-sm !default;
$kendo-line-height-md: $kendo-line-height !default;
$kendo-line-height-lg: $line-height-lg !default;
$kendo-line-height-em: calc( #{$kendo-line-height} * 1em ) !default;

// Font weight
$kendo-font-weight-light: $font-weight-light !default;
$kendo-font-weight-normal: $font-weight-normal !default;
$kendo-font-weight-medium: 500 !default; // TODO: use from bootstrap when we update
$kendo-font-weight-semibold: 600 !default; // TODO: use from bootstrap when we update
$kendo-font-weight-bold: $font-weight-bold !default;

// Letter Spacing
$kendo-letter-spacing: null !default;


// TODO: refactor
$kendo-focus-shadow: null !default;

$kendo-transition: color .2s ease-in-out, background-color .2s ease-in-out, border-color .2s ease-in-out, box-shadow .2s ease-in-out !default;


// Disabled mixin variables
$kendo-disabled-filter: grayscale(.1) !default;
$kendo-disabled-opacity: .65 !default;

$kendo-disabled-styling: (
    opacity: $kendo-disabled-opacity,
    filter: $kendo-disabled-filter
) !default;


// Generic styles

// TODO: refactor once we extract drag drop as separate module

// Loading
$kendo-loading-opacity: .3 !default;
$kendo-zindex-loading: 100 !default;
