// web specific variables here...
$base-font-size: 12px;
$base-header-font-size: 12px;
$base-grid-header-font-size: 12px;
$base-header-font-weight: 600;
$base-small-font-size: 11px;
$base-page-min-height: 86vh;
$base-page-full-height: 88vh;
$base-page-min-width: 350px;
$primary-font-color: #000;
$base-page-min-width: 350px;
$default-border-radius: 4px;
//side panel
$base-side-panel-small-width: 25%;
$base-side-panel-medium-width: 35%;
$base-side-panel-large-width: 50%;
$base-side-panel-xlarge-width: 75%;
$base-side-panel-full-width: 100%;
$base-side-panel-xsmall-width: 20%;

$gray-700: #495057;
$gray-300: #dee2e6;
$gray-400: #c0c0c0;

//inputs
$base-input-padding: 3px;
$base-medium-input-padding: 5px;
