@import 'scss/theme/app-theme';

// common mtp styling
@import 'libs/shared/scss/mtp/mtp';

// kendo bootstrap theme overrides for this specific app,
// these need to be imported before any theme files for
// specific components below so that the variable overrides
// take effect
@import 'scss/kendo-bootstrap-theme/color-system';

// common styling used in all applications
@import 'libs/shared/scss/common/index';

@import 'scss/kendo-bootstrap-theme/grid';

// kendo ui bootstrap theme files for specific components
@import '@progress/kendo-theme-bootstrap/scss/grid';
@import '@progress/kendo-theme-bootstrap/scss/window';
@import '@progress/kendo-theme-bootstrap/scss/dialog';
@import '@progress/kendo-theme-bootstrap/scss/popup';
@import '@progress/kendo-theme-bootstrap/scss/dropdownlist';
@import '@progress/kendo-theme-bootstrap/scss/combobox';
@import '@progress/kendo-theme-bootstrap/scss/splitter';
@import '@progress/kendo-theme-bootstrap/scss/tabstrip';
@import '@progress/kendo-theme-bootstrap/scss/loader';
@import '@progress/kendo-theme-bootstrap/scss/stepper';
@import '@progress/kendo-theme-bootstrap/scss/listview';
@import '@progress/kendo-theme-bootstrap/scss/scheduler';
@import '@progress/kendo-theme-bootstrap/scss/colorpicker';
@import '@progress/kendo-theme-bootstrap/scss/multiselect';
@import '@progress/kendo-theme-bootstrap/scss/dropdowngrid';
@import '@progress/kendo-theme-bootstrap/scss/upload';
@import '@progress/kendo-theme-bootstrap/scss/input';
@import '@progress/kendo-theme-bootstrap/scss/switch';
@import '@progress/kendo-theme-bootstrap/scss/tooltip';
@import "bootstrap-icons/font/bootstrap-icons.scss";

:root {
  .lpx-theme-dark {
    --lpx-logo: url('assets/images/logo/logo-dark.png');
    --lpx-logo-icon: url('assets/images/logo/imgLogoSAPP.png');
    --lpx-brand: $primaryColor;
  }

  .lpx-theme-dim {
    --lpx-logo: url('assets/images/logo/logo.png');
    --lpx-logo-icon: url('assets/images/logo/imgLogoSAPP.png');
    --lpx-brand: $primaryColor;
  }

  .lpx-theme-light {
    --lpx-logo: url('assets/images/logo/logo-light.png');
    --lpx-logo-icon: url('assets/images/logo/imgLogoSAPP.png');
    --lpx-brand: $primaryColor;
  }

  // make links text visible
  .nav-pills {
    --bs-nav-pills-link-active-color: #fff;
  }

  // replace login gradient background with image
  .lpx-login-image-area {
    background-image: url('assets/images/background.png');
  }

  // hide lepton copyright text and link
  .copyright-text {
    display: none;
  }
}
