@import "@progress/kendo-theme-default/scss/scheduler/_layout.scss";


@mixin kendo-scheduler--layout() {

    @include kendo-scheduler--layout-base();

    // Appointments
    kendo-scheduler .k-event,
    .k-event {
        &::before {
            display: none;
            left: 0;

            .k-rtl & {
                right: 0;
            }
        }

        // Angular fix for stronger selector and smaller padding
        .k-event-template {
            & { // stylelint-disable-line
                padding-block: $kendo-padding-sm-y;
                padding-inline: $kendo-padding-sm-x;
                font-size: $kendo-font-size-sm;
            }
        }

        .k-event-actions {
            right: $kendo-padding-sm-x;

            &:first-child {
                margin: .1ex .4ex 0 $kendo-padding-sm-x;
            }
        }
    }

}
