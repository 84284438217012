.k-treeview {
  width: 98% !important;
  font-size: 11px !important;
  font-weight: 400 !important;
}

.k-treeview-lines {
  list-style-type: none !important;
  cursor: pointer !important;
}

.k-treeview-leaf-text:hover {
  background-color: $gray-300;
}

.k-treeview-group {
  list-style-type: none !important;
}

.k-selected {
  border-color: $gray-300 !important;
  border-style: solid;
}
