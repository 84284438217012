@import "@progress/kendo-theme-default/scss/input/_layout.scss";

@mixin kendo-input--layout() {

    @include kendo-input--layout-base();

    // Input, Textbox
    .k-textbox {
        transition: $kendo-transition;
    }

}
