@import "@progress/kendo-theme-default/scss/menu/_layout.scss";

@mixin kendo-menu--layout() {

    @include kendo-menu--layout-base();

    .k-menu.k-menu-horizontal:not(.k-context-menu) {
        padding-block: $navbar-padding-y;
        padding-inline: $navbar-padding-x;
    }
    .k-menu.k-menu-vertical:not(.k-context-menu) {
        padding-block: $navbar-padding-y;
        padding-inline: 0;
    }

    .k-menu-group,
    .k-menu-vertical {

        .k-menu-popup & {
            padding: 0;
        }
    }

    .k-context-menu-popup {
        > .k-popup:not(.k-menu-popup) {
            padding: 0;
        }

        .k-context-menu {
            @include border-radius( $kendo-border-radius-md );
        }

        .k-header {
            font-size: inherit;
            font-weight: inherit;
        }
    }

}
