// Please see
// https://www.telerik.com/kendo-angular-ui/components/styling/theme-bootstrap/customization-grid/
// for variables available to override for the kendo ui grid.
// Please also see the file located in
// node_modules\@progress\kendo-theme-bootstrap\scss\_variables.scss
// for all other variables available to override

//reduce grid sizes
.k-grid,
.k-grid .k-button,
.k.grid .k-input {
  font-size: $base-font-size !important;
}

k-grid .k-hierarchy-cell a {
  padding: 0px !important;
}

.k-grid tbody td {
  white-space: nowrap;
  border-left: 0 !important;
  line-height: 15px !important;
  margin: 0px !important;
  padding: 5px 2px 0px 2px !important;
  height: 30px !important;
}

.k-grid .k-grid-header .k-header .k-link {
  height: auto !important;
  word-break: break-all !important;
  word-wrap: break-word !important;
}

.k-grid .k-grid-header .k-header {
  font-size: $base-grid-header-font-size !important;
  font-weight: $base-header-font-weight !important;
  border-left: 0px !important;
  height: auto !important;
  word-break: unset !important;
  word-wrap: normal !important;
}

.k-grid .k-header {
  overflow: visible !important;
  white-space: normal !important;
}

.k-grid .k-column-title {
  white-space: normal !important;
}

.k-grid tbody tr {
  line-height: 5px !important;
  padding: 0px 5px 5px 5px !important;
  margin: 0px 5px 5px 5px !important;
}

.kgw-filter .k-filtercell {
  padding: 0px 0px 0px 2px !important;
  margin: 0px 0px 0px 2px !important;
}

.k-cell-inner .k-grid-filter {
  position: absolute !important;
  top: 0px !important;
  right: -8px !important;
}
.k-cell-inner .k-grid-filter,
.k-cell-inner .k-header-column-menu,
.k-cell-inner .k-grid-header-menu {
  width: 25px !important;
  padding: 0px !important;
}

.k-grid .k-hierarchy-cell {
  padding: 0px 0px 0px 2px !important;
  margin: 0px 0px 0px 2px !important;
}

.k-filtercell-wrapper {
  padding: 3px 0px 0px 2px !important;
}

.k.grid .k-detail-cell {
  padding: 0px 0px 0px 12px !important;
  margin: 0px 0px 0px 12px !important;
}

.k-grid .k-hierarchy-cell + td {
  width: 20px !important;
  padding: 0px 0px 0px 10px !important;
}

.k-colorpicker .k-picker-wrap {
  border-color: #ced4da !important;
  color: #ffffff !important;
  background-color: $secondaryColor !important;
  width: auto !important;
}

.text-area-input {
  width: 100% !important;
  font-size: 13px !important;
  white-space: pre-wrap !important;
}

.k-column-title {
  vertical-align: top !important;
  text-overflow: unset !important;
  white-space: pre-wrap !important;
  flex-shrink: 1;
  overflow: unset !important;
  word-break: unset !important;
  word-wrap: normal !important;
}

.k-grid .k-grid-header .k-header .k-link {
  height: auto !important;
  word-break: unset !important;
  word-wrap: normal !important;
}

.k-grid-header .k-header {
  vertical-align: top;
}

.line-through-grid-row {
  text-decoration: line-through !important;
}

.k-grid td.command-nowrap {
  text-overflow: unset !important;
}

.k-grid .k-icon {
  font-size: 14px !important;
  font-weight: 300 !important;
}

.k-grid .k-input {
  border-radius: $default-border-radius !important;
}

.k-hierarchy-cell {
  width: 5px !important;
  border-right: 0px !important;
}

.k-pager .k-selected {
  background: $primaryColor !important;
  color: $light !important;
}

.k-pager .k-icon-button {
  color: $primaryColor !important;
}

tr.row-highlight {
  background-color: #89fca6 !important;
}
