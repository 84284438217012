@import "@progress/kendo-theme-default/scss/loader/_layout.scss";


@mixin kendo-loader--layout() {

    @include kendo-loader--layout-base();

    .k-i-loading,
    .k-loading-image {

        &::before {
            border-color: currentColor;
            border-right-color: transparent !important; // stylelint-disable-line declaration-no-important
        }

        &::after {
            display: none !important; // stylelint-disable-line declaration-no-important
        }
    }

    .k-loading-image::before {
        border-width: k-map-get( $kendo-spacing, 1 );
    }

}
