@import "@progress/kendo-theme-default/scss/checkbox/_layout.scss";


@mixin kendo-checkbox--layout() {

    @include kendo-checkbox--layout-base();

    // Override inherited bootstrap border radius
    .k-checkbox.k-rounded-sm {
        border-radius: k-math-div( $kendo-border-radius-sm, 2 );
    }
    .k-checkbox.k-rounded-md {
        border-radius: $kendo-border-radius-sm;
    }
    .k-checkbox.k-rounded-lg {
        border-radius: $kendo-border-radius-md;
    }

}
