.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: $secondaryColor !important;
}

.track circle {
  stroke: $primaryColor !important;
}

.loading-panel {
  .mat-progress-spinner {
    display: float;
  }
}
