//see theme.lepton5.css for styles to override
//body
body {
  background-color: $bodyBackgroundColor !important;
  background: $bodyBackgroundColor !important;
}

@keyframes donut-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

//chat
.bg-primary.mb-1.px-4.py-3 {
  background-color: $primaryColor !important;
}

.list-group-item.bg-light.text-muted {
  background: $greyLight1 !important;
}

.bg-light.border-top.m-0 {
  background: $greyLight1 !important;
  color: $fontBlack !important;
}

.bg-light.mb-1.px-4.py-3 {
  background: $light !important;
}

.p-3.bg-light.border-bottom {
  background: $greyLight1 !important;
  color: $fontBlack !important;
}

.chat-box {
  background: $dialogBackground !important;
}

.list-group .list-group-item.active,
.list-group .list-group-item.selected {
  background: $accent !important;
}

.donut {
  display: inline-block;
  border: 4px solid $secondaryColor; //rgba(0, 0, 0, 0.1);
  border-left-color: $primaryColor;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: donut-spin 1.2s linear infinite;

  &.centered {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

//logo
//TODO: make logo sizing responsive
.navbar-brand {
  width: 172px !important;
  height: 32px !important;
  background: $logoUrl center left !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  z-index: 106 !important;
  top: 21px !important;
  left: 68px !important;
  position: absolute !important;
}

//menu
//TODO: test for both top and side menu styling
//TODO: refactor to use SCSS nesting
//https://sass-lang.com/documentation/style-rules
.lp-opened-sidebar .lp-sidebar .lp-sidebar-navi ul li a:hover > span.lp-icon,
.lp-closed .lp-sidebar .lp-sidebar-navi ul li a:hover > span.lp-icon {
  color: $iconColor !important;
}

.lp-opened-sidebar .lp-sidebar .lp-sidebar-navi > ul > li.current > a > .lp-icon,
.lp-opened-sidebar .lp-sidebar .lp-sidebar-navi > ul > li.active-page > a > .lp-icon {
  color: $iconColor !important;
}

.lp-opened-sidebar .lp-sidebar .lp-sidebar-navi ul li.current > a > .lp-arrow-icon {
  color: $iconColor !important;
}

.lp-opened-sidebar .lp-sidebar .lp-sidebar-navi ul li.current > ul .current > a:before,
.lp-opened-sidebar .lp-sidebar .lp-sidebar-navi ul li.current > ul .active-page > a:before {
  color: $iconColor !important;
}

.lp-opened-sidebar .lp-sidebar .lp-sidebar-navi ul li.current > a > .lp-arrow-icon,
.lp-opened-sidebar .lp-sidebar .lp-sidebar-navi ul li.active-page > a > .lp-arrow-icon,
.lp-closed .lp-sidebar .lp-sidebar-navi ul li.current > a > .lp-arrow-icon,
.lp-closed .lp-sidebar .lp-sidebar-navi ul li.active-page > a > .lp-arrow-icon {
  color: $iconColor !important;
}

.lp-opened-sidebar .lp-sidebar .lp-sidebar-navi ul li.current > ul .current > a:before,
.lp-opened-sidebar .lp-sidebar .lp-sidebar-navi ul li.current > ul .active-page > a:before,
.lp-opened-sidebar .lp-sidebar .lp-sidebar-navi ul li.active-page > ul .current > a:before,
.lp-opened-sidebar .lp-sidebar .lp-sidebar-navi ul li.active-page > ul .active-page > a:before,
.lp-closed .lp-sidebar .lp-sidebar-navi ul li.current > ul .current > a:before,
.lp-closed .lp-sidebar .lp-sidebar-navi ul li.current > ul .active-page > a:before,
.lp-closed .lp-sidebar .lp-sidebar-navi ul li.active-page > ul .current > a:before,
.lp-closed .lp-sidebar .lp-sidebar-navi ul li.active-page > ul .active-page > a:before {
  border: 2px solid $iconColor !important;
}

.lp-opened-sidebar .lp-sidebar .lp-sidebar-navi > ul > li.current,
.lp-opened-sidebar .lp-sidebar .lp-sidebar-navi > ul > li.active-page,
.lp-closed .lp-sidebar .lp-sidebar-navi > ul > li.current,
.lp-closed .lp-sidebar .lp-sidebar-navi > ul > li.active-page {
  color: $iconColor !important;
}

.lp-opened-sidebar .lp-sidebar .lp-sidebar-navi > ul > li.current > a > .lp-icon,
.lp-opened-sidebar .lp-sidebar .lp-sidebar-navi > ul > li.active-page > a > .lp-icon,
.lp-closed .lp-sidebar .lp-sidebar-navi > ul > li.current > a > .lp-icon,
.lp-closed .lp-sidebar .lp-sidebar-navi > ul > li.active-page > a > .lp-icon {
  color: $iconColor !important;
}

.lp-topmenu .lp-sidebar .lp-sidebar-navi ul li a:hover > span.lp-icon {
  color: $iconColor !important;
}

.lp-topmenu .lp-sidebar .lp-sidebar-navi ul li.current > a > .lp-arrow-icon,
.lp-topmenu .lp-sidebar .lp-sidebar-navi ul li.active-page > a > .lp-arrow-icon {
  color: $iconColor !important;
}

.lp-topmenu .lp-sidebar .lp-sidebar-navi > ul > li.current,
.lp-topmenu .lp-sidebar .lp-sidebar-navi > ul > li.active-page {
  color: $iconColor !important;
}

.lp-topmenu .lp-sidebar .lp-sidebar-navi > ul > li.current > a > .lp-icon,
.lp-topmenu .lp-sidebar .lp-sidebar-navi > ul > li.active-page > a > .lp-icon {
  color: $iconColor !important;
}

.lp-topmenu .lp-sidebar .lp-sidebar-wrapper nav ul li.current > a .lp-icon {
  color: $iconColor !important;
}

.lp-topmenu .lp-sidebar .lp-sidebar-wrapper nav ul ul li a:hover .lp-icon {
  color: $iconColor !important;
}

.lp-topmenu .lp-sidebar .lp-sidebar-wrapper nav ul li:hover .lp-icon {
  color: $iconColor !important;
}

//side menu background color
.lp-opened-sidebar .lp-sidebar,
.lp-closed .lp-sidebar {
  background-color: $menuColor !important;
}

body.lp-topmenu header {
  background-color: $menuColor !important;
}

//nav bar background color
.navbar.navbar-expand-lg.user-nav-mobile {
  background-color: $menuColor !important;
}

//footer
.lp-footer {
  color: $footerColor !important;
  background: $footerBackground !important;
}

//progress bar
abp-loader-bar .abp-progress {
  background-color: $primaryColor !important;
}

//datepicker
.datepicker table tr td span.active,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active:hover,
.datepicker table tr td.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active:hover {
  background: $primaryColor !important;
}

//Buttons
.btn {
  box-shadow: none !important;
}

.btn.btn-primary {
  background-color: $primaryColor !important;
  border-color: $primaryColor !important;
  box-shadow: none !important;
}

.btn.btn-primary:hover,
.btn.btn-primary:focus,
.btn.btn-primary:active,
.btn.btn-primary:not(:disabled):not(.disabled):active {
  background-color: $primaryColor !important;
  border-color: $primaryColor !important;
  box-shadow: none !important;
}

.btn.btn-outline-primary {
  color: $primaryColor !important;
  border-color: $primaryColor !important;
}

.btn.btn-outline-primary:hover,
.btn.btn-outline-primary:focus,
.btn.btn-outline-primary:active,
.btn.btn-outline-primary:not(:disabled):not(.disabled):active {
  background-color: $primaryColor !important;
  border-color: $primaryColor !important;
  box-shadow: none !important;
}

.btn.btn-secondary {
  background-color: $secondaryColor !important;
  border-color: $secondaryColor !important;
}

.btn.btn-secondary:hover,
.btn.btn-secondary:focus,
.btn.btn-secondary:active,
.btn.btn-secondary:not(:disabled):not(.disabled):active {
  background-color: $secondaryColor !important;
  border-color: $secondaryColor !important;
}

.btn.btn-outline-secondary {
  color: $secondaryColor !important;
  border-color: $secondaryColor !important;
}

.btn.btn-outline-secondary:hover,
.btn.btn-outline-secondary:focus,
.btn.btn-outline-secondary:active,
.btn.btn-outline-secondary:not(:disabled):not(.disabled):active {
  background-color: $secondaryColor !important;
  border-color: $secondaryColor !important;
}

.confirmation .confirmation-dialog .footer .confirmation-button {
  background-color: $primaryColor !important;
  padding: 5px 15px 5px 12px !important;
  border-radius: 0 !important;
  font-size: 12px !important;
  font-weight: normal !important;
}

#cancel {
  background-color: $secondaryColor !important;
  border-color: $secondaryColor !important;
}

abp-confirmation .confirmation .confirmation-backdrop {
  background-color: $modalBackdrop !important;
}

.confirmation .confirmation-dialog .footer {
  background: $footerBackground !important;
}

.confirmation .confirmation-dialog {
  background: $dialogBackground !important;
}

// Pagination
.ui-table .pagination-wrapper .page-item.active .page-link {
  background-color: $primaryColor !important;
}

.paginate_button.page-item.active a.page-link {
  background: $primaryColor !important;
}

.ui-table .pagination-wrapper .page-item.active .page-link {
  background-color: $primaryColor !important;
}

//form control
.form-control .ng-dirty .ng-valid .ng-touched {
  border-color: $borderColor !important;
  box-shadow: none !important;
}

.input-group .form-control:focus,
.form-control:active,
.form-control:visited,
.custom-select:focus,
.custom-select:active,
.custom-select:visited,
.custom-file-label:focus,
.custom-file-label:active,
.custom-file-label:visited {
  box-shadow: none !important;
}
//custom  select
.form-control:focus,
.form-control:active,
.form-control:visited,
.custom-select:focus,
.custom-select:active,
.custom-select:visited,
.custom-file-label:focus,
.custom-file-label:active,
.custom-file-label:visited {
  border-color: $borderColor !important;
  box-shadow: none !important;
}

//nav.pills
.nav-pills.flex-column .nav-link.active {
  border: $primaryColor !important;
  background: $primaryColor !important;
}

.nav-pills .nav-item .nav-link.active {
  border: $primaryColor !important;
  background: $primaryColor !important;
}

.nav-pills .nav-item .nav-link.active:after {
  border-top: $primaryColor !important;
}

.nav-pills.flex-column .nav-link.active {
  border: $primaryColor !important;
  background: $primaryColor !important;
}

.nav-pills.flex-column .nav-link.active:after {
  border-left: $primaryColor !important;
}

//datepicker
ngb-datepicker .ng-range-end {
  background: $primaryColor !important;
}

//alert
.alert.alert-primary {
  border-color: $primaryColor !important;
  color: $primaryColor !important;
}

.alert.alert-primary a,
.alert.alert-primary .fa {
  color: $primaryColor !important;
}

.alert.alert-secondary {
  border-color: $secondaryColor !important;
}

.alert.alert-secondary a,
.alert.alert-secondary .fa,
.alert.alert-secondary .alert-heading {
  color: $secondaryColor !important ;
}

// custom radio control
.custom-radio .custom-control-input:checked ~ .custom-control-label::before,
.custom-control-input:checked ~ .custom-control-label::before {
  background-color: $primaryColor !important;
}

//swal
.swal-modal .swal-footer .swal-button-container .swal-button {
  background-color: $primaryColor !important;
  border-color: $borderColor !important;
}

.swal-modal .swal-footer .swal-button-container .swal-button:hover,
.swal-modal .swal-footer .swal-button-container .swal-button:focus,
.swal-modal .swal-footer .swal-button-container .swal-button:active,
.swal-modal .swal-footer .swal-button-container .swal-button:not(:disabled):not(.disabled):active {
  background-color: $primaryColor !important;
  border-color: $primaryColor !important;
}

//swal end

.modal-backdrop {
  background: $modalBackdrop !important;
}

//Container
.select2-container--default .select2-search--dropdown .select2-search__field:focus {
  color: $primaryColor !important;
  border-color: $borderColor !important;
}

//Accordian
.accordion h5 {
  color: $primaryColor !important;
}

//nav-tabs
.nav-tabs .nav-item .nav-link.active {
  color: $primaryColor !important;
}

//listgroup
.list-group .list-group-item:hover .fa-notification-icon {
  color: $primaryColor !important;
}

//datatables
.dataTables_wrapper .btn:hover,
.dataTables_wrapper .btn:focus,
.dataTables_wrapper .btn:active,
.dataTables_wrapper .btn:not(:disabled):not(.disabled):active {
  background-color: $primaryColor !important;
  border-color: $borderColor !important;
}

table.dataTable thead th.sorting_asc:before,
table.dataTable tfoot th.sorting_asc:before {
  color: $primaryColor !important;
}

table.dataTable thead th.sorting_desc:after,
table.dataTable tfoot th.sorting_desc:after {
  color: $primaryColor !important;
}

//ui table
body .ui-table .ui-table-tbody > tr.ui-table-dragpoint-top > td {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

body .ui-table .ui-table-tbody > tr.ui-table-dragpoint-bottom > td {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.card .card-body {
  margin: 0 !important;
  padding: 0px !important;
}

.card {
  box-shadow: none !important;
}

/**
 * Temporary fix for the issue with the close button in the alert component.
 *
 *
 */

.alert button.close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #325168;
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23325168'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e")
    center/0.5em auto no-repeat;
  border: 0;
  border-radius: 0.5rem;
  opacity: 0.5;
}

.alert button.close span {
  display: none !important;
}
.alert button.close:hover {
  color: #325168;
  text-decoration: none;
  opacity: 0.75;
}
.alert .close:focus {
  outline: 0;
  box-shadow: none;
  opacity: 1;
}
.alert .close:disabled,
.alert .close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: unset;
}

.alert .close:hover,
.alert .close:focus,
.alert .close:active,
.alert .close:not(:disabled):not(.disabled):active,
.alert .close.visible {
  outline: none;
  box-shadow: none;
  opacity: 0.5;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1.5rem;
  --bs-alert-padding-y: 1.5rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 0 solid var(--bs-alert-border-color);
  --bs-alert-border-radius: 0.5rem;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: $primary-font-color !important;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4.5rem;
}
.alert-dismissible button.close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
}

.alert-primary {
  --bs-alert-color: #203899;
  --bs-alert-bg: #d7dfff;
  --bs-alert-border-color: #c2ceff;
}
.alert-primary .alert-link {
  color: #1a2d7a;
}

.alert-secondary {
  --bs-alert-color: #41387f;
  --bs-alert-bg: #e2dff6;
  --bs-alert-border-color: #d3cef2;
}
.alert-secondary .alert-link {
  color: #342d66;
}

.alert-success {
  --bs-alert-color: #2f733e;
  --bs-alert-bg: #dcf2e1;
  --bs-alert-border-color: #caecd1;
}
.alert-success .alert-link {
  color: #265c32;
}

.alert-info {
  --bs-alert-color: #c6d2fe;
  --bs-alert-bg: #c6d2fe;
  --bs-alert-border-color: #c7dce5;
}
.alert-info .alert-link {
  color: #204250;
}

.alert-warning {
  --bs-alert-color: #995f22;
  --bs-alert-bg: #ffecd7;
  --bs-alert-border-color: #ffe2c3;
}
.alert-warning .alert-link {
  color: #7a4c1b;
}

.alert-danger {
  --bs-alert-color: #73082c;
  --bs-alert-bg: #f2cfdb;
  --bs-alert-border-color: #ecb6c8;
}
.alert-danger .alert-link {
  color: #5c0623;
}

.alert-light {
  --bs-alert-color: #5c5e60;
  --bs-alert-bg: #fafbfc;
  --bs-alert-border-color: #f8f9fa;
}
.alert-light .alert-link {
  color: #4a4b4d;
}

.alert-dark {
  --bs-alert-color: #041929;
  --bs-alert-bg: #cdd4da;
  --bs-alert-border-color: #b4bfc7;
}
.alert-dark .alert-link {
  color: #031421;
}

.alert-brand {
  background: rgba(247, 37, 133, 0.3);
  border: 1px solid rgba(247, 37, 133, 0.3);
  -webkit-backdrop-filter: saturate(100%) blur(10px);
  backdrop-filter: saturate(100%) blur(10px);
  position: relative;
  color: #325168;
}
.alert-brand:before {
  content: '';
  background: rgba(240, 244, 247, 0.1);
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 0.5rem;
}
.alert-brand a,
.alert-brand .bi,
.alert-brand .fa,
.alert-brand .icon,
.alert-brand .highlight,
.alert-brand .alert-link {
  color: #062a44;
}

.alert-primary {
  background: rgba(53, 93, 255, 0.3);
  border: 1px solid rgba(53, 93, 255, 0.3);
  -webkit-backdrop-filter: saturate(100%) blur(10px);
  backdrop-filter: saturate(100%) blur(10px);
  position: relative;
  color: #325168;
}
.alert-primary:before {
  content: '';
  background: rgba(240, 244, 247, 0.1);
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 0.5rem;
}
.alert-primary a,
.alert-primary .bi,
.alert-primary .fa,
.alert-primary .icon,
.alert-primary .highlight,
.alert-primary .alert-link {
  color: #062a44;
}

.alert-secondary {
  background: rgba(108, 93, 211, 0.3);
  border: 1px solid rgba(108, 93, 211, 0.3);
  -webkit-backdrop-filter: saturate(100%) blur(10px);
  backdrop-filter: saturate(100%) blur(10px);
  position: relative;
  color: #325168;
}
.alert-secondary:before {
  content: '';
  background: rgba(240, 244, 247, 0.1);
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 0.5rem;
}
.alert-secondary a,
.alert-secondary .bi,
.alert-secondary .fa,
.alert-secondary .icon,
.alert-secondary .highlight,
.alert-secondary .alert-link {
  color: #062a44;
}

.alert-success {
  background: rgba(79, 191, 103, 0.3);
  border: 1px solid rgba(79, 191, 103, 0.3);
  -webkit-backdrop-filter: saturate(100%) blur(10px);
  backdrop-filter: saturate(100%) blur(10px);
  position: relative;
  color: #325168;
}
.alert-success:before {
  content: '';
  background: rgba(240, 244, 247, 0.1);
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 0.5rem;
}
.alert-success a,
.alert-success .bi,
.alert-success .fa,
.alert-success .icon,
.alert-success .highlight,
.alert-success .alert-link {
  color: #062a44;
}

.alert-info {
  background: rgb(198, 210, 254);

  -webkit-backdrop-filter: saturate(100%) blur(10px);
  backdrop-filter: saturate(100%) blur(10px);
  position: relative;
}
.alert-info:before {
  content: '';
  background: rgba(240, 244, 247, 0.1);
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 0.5rem;
}
.alert-info a,
.alert-info .bi,
.alert-info .fa,
.alert-info .icon,
.alert-info .highlight,
.alert-info .alert-link {
  color: #062a44;
}

.alert-warning {
  background: rgba(255, 159, 56, 0.3);
  border: 1px solid rgba(255, 159, 56, 0.3);
  -webkit-backdrop-filter: saturate(100%) blur(10px);
  backdrop-filter: saturate(100%) blur(10px);
  position: relative;
  color: #325168;
}
.alert-warning:before {
  content: '';
  background: rgba(240, 244, 247, 0.1);
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 0.5rem;
}
.alert-warning a,
.alert-warning .bi,
.alert-warning .fa,
.alert-warning .icon,
.alert-warning .highlight,
.alert-warning .alert-link {
  color: #062a44;
}

.alert-danger {
  background: rgba(192, 13, 73, 0.3);
  border: 1px solid rgba(192, 13, 73, 0.3);
  -webkit-backdrop-filter: saturate(100%) blur(10px);
  backdrop-filter: saturate(100%) blur(10px);
  position: relative;
  color: #325168;
}
.alert-danger:before {
  content: '';
  background: rgba(240, 244, 247, 0.1);
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 0.5rem;
}
.alert-danger a,
.alert-danger .bi,
.alert-danger .fa,
.alert-danger .icon,
.alert-danger .highlight,
.alert-danger .alert-link {
  color: #062a44;
}

.alert-light {
  background: rgba(230, 235, 239, 0.3);
  border: 1px solid rgba(230, 235, 239, 0.3);
  -webkit-backdrop-filter: saturate(100%) blur(10px);
  backdrop-filter: saturate(100%) blur(10px);
  position: relative;
  color: #325168;
}
.alert-light:before {
  content: '';
  background: rgba(240, 244, 247, 0.1);
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 0.5rem;
}
.alert-light a,
.alert-light .bi,
.alert-light .fa,
.alert-light .icon,
.alert-light .highlight,
.alert-light .alert-link {
  color: #062a44;
}

.alert-dark {
  background: rgba(6, 42, 68, 0.3);
  border: 1px solid rgba(6, 42, 68, 0.3);
  -webkit-backdrop-filter: saturate(100%) blur(10px);
  backdrop-filter: saturate(100%) blur(10px);
  position: relative;
  color: #325168;
}
.alert-dark:before {
  content: '';
  background: rgba(240, 244, 247, 0.1);
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 0.5rem;
}
.alert-dark a,
.alert-dark .bi,
.alert-dark .fa,
.alert-dark .icon,
.alert-dark .highlight,
.alert-dark .alert-link {
  color: #062a44;
}

.alert-light,
.alert-dark {
  color: #325168;
}
/**/
