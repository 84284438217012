// Please see
// https://www.telerik.com/kendo-angular-ui/components/styling/theme-bootstrap/customization-buttons/
// for variables available to override for the kendo ui grid.
// Please also see the file located in
// node_modules\@progress\kendo-theme-bootstrap\scss\_variables.scss
// for all other variables available to override

.k-button {
  border-radius: $default-border-radius !important;
  font-size: $base-font-size !important;
}

.k-button:disabled {
  pointer-events: auto !important;
}

.k-widget.k-upload .k-action-buttons {
  display: none;
}

.k-widget .k-upload {
  border-color: $gray-400 !important;
  border-style: dashed !important;
  border-radius: 8px !important;
  border-width: thin !important;
  padding-bottom: 10px !important;
  text-align: center !important;
}

.k-widget .k-upload:before {
  font-family: 'Font Awesome 5 Free' !important;
  font-weight: 900 !important;
  font-size: 35px !important;
  color: $gray-400;
  content: '\f093' !important;
}

.k-button fa-icon {
  font-size: $base-font-size !important;
}

.k-button-prominent {
  color: $iconColor !important;
}

.k-button-prominent fa-icon {
  color: $iconColor !important;
}

//alignment of models buttons
.text-right,
.k-button {
  text-align: right !important;
}

.k-button-solid-base {
  background-color: $secondaryColor !important;
  color: $light !important;
}
