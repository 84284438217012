.mat-menu-item:disabled {
  pointer-events: auto !important;
}

.mat-menu-item {
  font-size: $base-small-font-size !important;
  border: 0px;
}

.mat-mdc-menu-content, .mat-mdc-menu-content .mat-mdc-menu-item .mdc-list-item__primary-text {
  --mat-menu-item-label-text-size: 11px;
  font-size: var(--mat-menu-item-label-text-size) !important;
}

.mat-mdc-menu-item {
  min-height: 30px !important;
}

