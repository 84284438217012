.home-background {
  background-image: url(/assets/images/background.png);
  background-repeat: no-repeat;
  background-position: center !important;
  min-height: 90vh !important;
  height: 100% !important;
  width: 100%;
  vertical-align: bottom !important;
  background-size: contain;
}
