$primaryColor: #00672f;
$secondaryColor: #fd9b00;
$loaderBackgroundColor: #f4f4f4;
$loaderStroke: $secondaryColor;
$bodyBackgroundColor: #f4f4f4;
$iconColor: #f47d2f;
$logoUrl: url(/assets/images/logo/logo.png);
$menuColor: #ffffff;
$footerColor: $primaryColor;
$footerBackground: #f4f4f4;
$accent: $primaryColor;
$light: #f9f9f9;
$borderColor: #d5d5d5;
$modalBackdrop: rgba(0, 0, 0, 0.6);
$dialogBackground: #ffffff;
$fontBlack: #000000;
$greyLight1: #ebebeb;
$kendo-button-text: #ffffff;
$sapplogoIcon: url(/assets/images/logo/imgLogoSAPP.png);
$sadcLogoIcon: url(/assets/images/logo/imgLogoSADC.png);

.bg-primary {
  background: $primaryColor !important;
}
