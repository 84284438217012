// Please see
// https://www.telerik.com/kendo-angular-ui/components/styling/theme-bootstrap/customization-component/
// for variables available to override for the kendo ui grid.
// Please also see the file located in
// node_modules\@progress\kendo-theme-bootstrap\scss\_variables.scss
// for all other variables available to override

// Kendo dialogue window z-index - display order
.k-dialog-wrapper {
  z-index: 1001 !important;
}

.k-dialog-titlebar {
  padding: 0px !important;
}

.k-datepicker .k-picker-wrap.k-state-focused,
.k-datepicker .k-picker-wrap.k-state-active,
.k-timepicker .k-picker-wrap.k-state-focused,
.k-timepicker .k-picker-wrap.k-state-active,
.k-datetimepicker .k-picker-wrap.k-state-focused,
.k-datetimepicker .k-picker-wrap.k-state-active {
  border-color: $secondary !important;
  box-shadow: none !important;
}

.k-textbox:focus,
.k-textbox:focus-within,
.k-textbox.k-state-focus,
.k-textbox.k-state-focused {
  border-color: $secondary !important;
  box-shadow: none !important;
}

.k-combobox .k-dropdown-wrap.k-state-focused {
  box-shadow: none !important;
  border-color: $secondary !important;
}

.k-dropdown .k-dropdown-wrap:hover,
.k-dropdown .k-dropdown-wrap.k-state-hover {
  border-color: $secondary !important;
  background-color: $secondary !important;
}

.k-dropdown .k-dropdown-wrap.k-state-focused {
  border-color: $secondary !important;
  background-color: $secondary !important;
  box-shadow: none;
}

.k-picker-solid:focus {
  border: 1px !important;
  border-color: $secondary !important;
}
.k-picker-solid {
  border: 0px !important;
  background-color: #fff !important;
}

.k-switch,
.k-rounded-full {
  border-radius: 50px !important;
}

.k-stepper .k-step-label .k-step-text {
  white-space: pre-wrap !important;
}
