@import "./images/slider-h.scss";
@import "./images/slider-v.scss";


// Slider
$kendo-slider-size: 200px !default;
$kendo-slider-tick-size: null !default;
$kendo-slider-alt-size: 30px !default;

$kendo-slider-font-family: $kendo-font-family !default;
$kendo-slider-font-size: $kendo-font-size-md !default;
$kendo-slider-line-height: $kendo-line-height-md !default;

$kendo-slider-button-offset: 10px !default;
$kendo-slider-button-size: ($kendo-slider-alt-size - 2) !default;
$kendo-slider-button-spacing: ($kendo-slider-button-size + $kendo-slider-button-offset) !default;
$kendo-slider-button-focus-shadow: $kendo-button-focus-shadow !default;

$kendo-slider-track-thickness: 4px !default;
$kendo-slider-draghandle-size: 14px !default;
$kendo-slider-draghandle-border-width: 1px !default;
$kendo-slider-draghandle-active-scale: 1 !default;
$kendo-slider-draghandle-active-size: null !default;

$kendo-slider-draghandle-bg: $kendo-color-primary !default;
$kendo-slider-draghandle-text: if($kendo-enable-color-system, k-color( on-primary ), k-contrast-color( $kendo-color-primary )) !default;
$kendo-slider-draghandle-border: $kendo-color-primary !default;
$kendo-slider-draghandle-gradient: null !default;

$kendo-slider-draghandle-hover-bg: if($kendo-enable-color-system, k-color( primary-hover ), k-try-darken( $kendo-color-primary, 7.5% )) !default;
$kendo-slider-draghandle-hover-text: if($kendo-enable-color-system, k-color( on-primary ), k-contrast-color( $kendo-color-primary )) !default;
$kendo-slider-draghandle-hover-border: if($kendo-enable-color-system, k-color( primary-hover ), k-try-darken( $kendo-color-primary, 10% )) !default;
$kendo-slider-draghandle-hover-gradient: null !default;

$kendo-slider-draghandle-pressed-bg: if($kendo-enable-color-system, k-color( primary-active ), k-try-darken( $kendo-color-primary, 10% )) !default;
$kendo-slider-draghandle-pressed-text: if($kendo-enable-color-system, k-color( on-primary ), k-contrast-color( $kendo-color-primary )) !default;
$kendo-slider-draghandle-pressed-border: if($kendo-enable-color-system, k-color( primary-active ), k-try-darken( $kendo-color-primary, 12.5% )) !default;
$kendo-slider-draghandle-pressed-gradient: null !default;

$kendo-slider-draghandle-focus-shadow: 0 0 0 3px if($kendo-enable-color-system, rgba( k-color( primary, true ), .5 ), rgba( $kendo-color-primary, .5 )) !default;

$kendo-slider-transition-speed: .3s !default;
$kendo-slider-transition-function: ease-out !default;
$kendo-slider-draghandle-transition-speed: .4s !default;
$kendo-slider-draghandle-transition-function: cubic-bezier(.25, .8, .25, 1) !default;

$kendo-slider-track-bg: $gray-200 !default;
$kendo-slider-selection-bg: $kendo-selected-bg !default;
$kendo-slider-disabled-opacity: null !default;
