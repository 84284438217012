// Grid
$kendo-grid-border-width: 1px !default;
$kendo-grid-font-family: $kendo-font-family !default;
$kendo-grid-font-size: $kendo-font-size-md !default;
$kendo-grid-line-height: $kendo-line-height-md !default;

/// Horizontal padding of the grid.
/// @group grid
$kendo-grid-padding-x: $kendo-table-md-cell-padding-x !default;
/// Vertical padding of the grid.
/// @group grid
$kendo-grid-padding-y: $kendo-table-md-cell-padding-y !default;

/// Horizontal padding of the grid header.
/// @group grid
$kendo-grid-header-padding-x: $kendo-grid-padding-x !default;
/// Vertical padding of the grid header.
/// @group grid
$kendo-grid-header-padding-y: $kendo-grid-padding-y !default;


/// Horizontal padding of the grid grouping header.
/// @group grid
$kendo-grid-grouping-header-padding-x: $kendo-grid-padding-y !default;
/// Vertical padding of the grid grouping header.
/// @group grid
$kendo-grid-grouping-header-padding-y: $kendo-grid-grouping-header-padding-x !default;

/// Horizontal padding of the grid cell.
/// @group grid
$kendo-grid-cell-padding-x: $kendo-grid-padding-x !default;
/// Vertical padding of the grid cell.
/// @group grid
$kendo-grid-cell-padding-y: $kendo-grid-padding-y !default;

/// Horizontal padding of the grid filter cell.
/// @group grid
$kendo-grid-filter-cell-padding-x: $kendo-grid-padding-y !default;
/// Vertical padding of the grid filter cell.
/// @group grid
$kendo-grid-filter-cell-padding-y: $kendo-grid-filter-cell-padding-x !default;

/// Horizontal padding of the grid edit cell.
/// @group grid
$kendo-grid-edit-cell-padding-x: $kendo-grid-cell-padding-x !default;
/// Vertical padding of the grid edit cell.
/// @group grid
$kendo-grid-edit-cell-padding-y: 5px !default;

$kendo-grid-group-drop-hint-size: 6px !default;
$kendo-grid-group-drop-hint-line-size: k-math-div( $kendo-grid-group-drop-hint-size, 3) !default;
$kendo-grid-group-drop-hint-height: calc( #{$kendo-chip-calc-size} + #{$kendo-grid-group-drop-hint-size}) !default;
$kendo-grid-group-drop-hint-top: 3px !default;

$kendo-grid-sm-padding-x: k-map-get( $kendo-spacing, 1 ) !default;
$kendo-grid-sm-padding-y: k-map-get( $kendo-spacing, 1 ) !default;
$kendo-grid-sm-header-padding-x: $kendo-grid-sm-padding-x !default;
$kendo-grid-sm-header-padding-y: $kendo-grid-sm-padding-y !default;
$kendo-grid-sm-grouping-header-padding-x: $kendo-grid-padding-x !default;
$kendo-grid-sm-grouping-header-padding-y: $kendo-grid-sm-padding-y !default;
$kendo-grid-sm-cell-padding-x: $kendo-grid-sm-padding-x !default;
$kendo-grid-sm-cell-padding-y: $kendo-grid-sm-padding-y !default;
$kendo-grid-sm-filter-cell-padding-x: k-map-get( $kendo-spacing, 1.5 ) !default;
$kendo-grid-sm-filter-cell-padding-y: $kendo-grid-sm-filter-cell-padding-x !default;
$kendo-grid-sm-edit-cell-padding-x: k-map-get( $kendo-spacing, 1 ) !default;
$kendo-grid-sm-edit-cell-padding-y: k-map-get( $kendo-spacing, 1 ) !default;
$kendo-grid-sm-group-dropclue-height: calc( #{$kendo-chip-sm-calc-size} + #{$kendo-grid-group-drop-hint-size}) !default;
$kendo-grid-sm-selection-aggregates-padding-x: $kendo-grid-sm-padding-y !default;
$kendo-grid-sm-selection-aggregates-padding-y: $kendo-grid-sm-padding-y !default;

$kendo-grid-md-padding-x: $kendo-grid-padding-x !default;
$kendo-grid-md-padding-y: $kendo-grid-padding-y !default;
$kendo-grid-md-header-padding-x: $kendo-grid-header-padding-x !default;
$kendo-grid-md-header-padding-y: $kendo-grid-header-padding-y !default;
$kendo-grid-md-grouping-header-padding-x: $kendo-grid-grouping-header-padding-x !default;
$kendo-grid-md-grouping-header-padding-y: $kendo-grid-grouping-header-padding-y !default;
$kendo-grid-md-cell-padding-x: $kendo-grid-cell-padding-x !default;
$kendo-grid-md-cell-padding-y: $kendo-grid-cell-padding-y !default;
$kendo-grid-md-filter-cell-padding-x: $kendo-grid-filter-cell-padding-x !default;
$kendo-grid-md-filter-cell-padding-y: $kendo-grid-filter-cell-padding-y !default;
$kendo-grid-md-edit-cell-padding-x: $kendo-grid-edit-cell-padding-x !default;
$kendo-grid-md-edit-cell-padding-y: $kendo-grid-edit-cell-padding-y !default;
$kendo-grid-md-group-dropclue-height: $kendo-grid-group-drop-hint-height !default;
$kendo-grid-md-selection-aggregates-padding-x: $kendo-grid-padding-y !default;
$kendo-grid-md-selection-aggregates-padding-y: $kendo-grid-padding-y !default;


// Kendo Grid sizes
$kendo-grid-sizes: (
    sm: (
        header-padding-x: $kendo-grid-sm-header-padding-x,
        header-padding-y: $kendo-grid-sm-header-padding-y,
        grouping-header-padding-x: $kendo-grid-sm-grouping-header-padding-x,
        grouping-header-padding-y: $kendo-grid-sm-grouping-header-padding-y,
        cell-padding-x: $kendo-grid-sm-cell-padding-x,
        cell-padding-y: $kendo-grid-sm-cell-padding-y,
        filter-cell-padding-x: $kendo-grid-sm-filter-cell-padding-x,
        filter-cell-padding-y: $kendo-grid-sm-filter-cell-padding-y,
        edit-cell-padding-x: $kendo-grid-sm-edit-cell-padding-x,
        edit-cell-padding-y: $kendo-grid-sm-edit-cell-padding-y,
        button-padding-y: $kendo-button-sm-padding-y,
        button-calc-size: $kendo-button-sm-calc-size,
        group-dropclue-height: $kendo-grid-sm-group-dropclue-height,
        selection-aggregates-padding-x: $kendo-grid-sm-selection-aggregates-padding-x,
        selection-aggregates-padding-y: $kendo-grid-sm-selection-aggregates-padding-y
    ),
    md: (
        header-padding-x: $kendo-grid-md-header-padding-x,
        header-padding-y: $kendo-grid-md-header-padding-y,
        grouping-header-padding-x: $kendo-grid-md-grouping-header-padding-x,
        grouping-header-padding-y: $kendo-grid-md-grouping-header-padding-y,
        cell-padding-x: $kendo-grid-md-cell-padding-x,
        cell-padding-y: $kendo-grid-md-cell-padding-y,
        filter-cell-padding-x: $kendo-grid-md-filter-cell-padding-x,
        filter-cell-padding-y: $kendo-grid-md-filter-cell-padding-y,
        edit-cell-padding-x: $kendo-grid-md-edit-cell-padding-x,
        edit-cell-padding-y: $kendo-grid-md-edit-cell-padding-y,
        button-padding-y: $kendo-button-md-padding-y,
        button-calc-size: $kendo-button-md-calc-size,
        group-dropclue-height: $kendo-grid-md-group-dropclue-height,
        selection-aggregates-padding-x: $kendo-grid-md-selection-aggregates-padding-x,
        selection-aggregates-padding-y: $kendo-grid-md-selection-aggregates-padding-y
    ),
) !default;

$kendo-grid-header-font-size: null !default;
$kendo-grid-header-first-border: 1px !default;
$kendo-grid-header-menu-icon-spacing: $kendo-icon-spacing !default;

$kendo-grid-filterable-icon-spacing: calc( #{$kendo-button-calc-size} + #{$kendo-grid-header-menu-icon-spacing} ) !default;

$kendo-grid-toolbar-border-width: $kendo-grid-border-width !default;

$kendo-grid-cell-horizontal-border-width: $kendo-table-cell-horizontal-border-width !default;
$kendo-grid-cell-vertical-border-width: $kendo-table-cell-vertical-border-width !default;

/// Background color of the grid component
/// @group grid
$kendo-grid-bg: $kendo-table-bg !default;
/// Text color of the grid component
/// @group grid
$kendo-grid-text: $kendo-table-text !default;
/// Border color of the grid component
/// @group grid
$kendo-grid-border: $kendo-table-border !default;

/// Background color of grid header
/// @group grid
$kendo-grid-header-bg: $kendo-table-header-bg !default;
/// Text color of grid header
/// @group grid
$kendo-grid-header-text: $kendo-table-header-text !default;
/// Border color of grid header
/// @group grid
$kendo-grid-header-border: $kendo-table-header-border !default;
/// Background gradient of grid header
/// @group grid
$kendo-grid-header-gradient: $kendo-table-header-gradient !default;

/// Background color of grid footer
/// @group grid
$kendo-grid-footer-bg: $kendo-table-footer-bg !default;
/// Text color of grid footer
/// @group grid
$kendo-grid-footer-text: $kendo-table-footer-text !default;
/// Border color of grid footer
/// @group grid
$kendo-grid-footer-border: $kendo-table-footer-border !default;

/// Background color of alternating rows in grid
/// @group grid
$kendo-grid-alt-bg: $kendo-table-alt-row-bg !default;
/// Text color of alternating rows in grid
/// @group grid
$kendo-grid-alt-text: $kendo-table-alt-row-text !default;
/// Border color of alternating rows in grid
/// @group grid
$kendo-grid-alt-border: $kendo-table-alt-row-border !default;

/// Background color of hovered rows in grid
/// @group grid
$kendo-grid-hover-bg: $kendo-table-hover-bg !default;
/// Text color of hovered rows in grid
/// @group grid
$kendo-grid-hover-text: $kendo-table-hover-text !default;
/// Border color of hovered rows in grid
/// @group grid
$kendo-grid-hover-border: $kendo-table-hover-border !default;

/// Background color of selected rows in grid
/// @group grid
$kendo-grid-selected-bg: $kendo-table-selected-bg !default;
/// Text color of selected rows in grid
/// @group grid
$kendo-grid-selected-text: $kendo-table-selected-text !default;
/// Border color of selected rows in grid
/// @group grid
$kendo-grid-selected-border: $kendo-table-selected-border !default;

/// Background color of the selection aggregates container
/// @group grid
$kendo-grid-selection-aggregates-bg: $kendo-grid-header-bg !default;
/// Text color of the selection aggregates container
/// @group grid
$kendo-grid-selection-aggregates-text: $kendo-grid-text !default;
/// Border color of the selection aggregates container
/// @group grid
$kendo-grid-selection-aggregates-border: $kendo-grid-border !default;
/// Border width of the selection aggregates container
/// @group grid
$kendo-grid-selection-aggregates-border-width: $kendo-grid-border-width !default;
/// Spacing between the selection aggregates items
/// @group grid
$kendo-grid-selection-aggregates-spacing: k-map-get( $kendo-spacing, 2 ) !default;
/// Line height of the selection aggregates container
/// @group grid
$kendo-grid-selection-aggregates-line-height: $kendo-grid-line-height !default;
/// Font weight of the selection aggregates container
/// @group grid
$kendo-grid-selection-aggregates-font-weight: $kendo-font-weight-bold !default;

$kendo-grid-grouping-row-bg: if($kendo-enable-color-system, k-color( base-subtle ), k-try-shade( $kendo-grid-bg )) !default;
$kendo-grid-grouping-row-text: $kendo-grid-text !default;

$kendo-grid-sorted-bg: if($kendo-enable-color-system, rgba( k-color( on-app-surface, true ), .02 ), rgba( k-contrast-color( $kendo-grid-bg ), .02 )) !default;
$kendo-grid-sorting-indicator-text: $kendo-color-primary !default;
$kendo-grid-sorting-index-font-size: ($kendo-icon-size * .75) !default;
$kendo-grid-sorting-index-height: $kendo-icon-size !default;
$kendo-grid-sorting-index-spacing-y: 2px !default;
$kendo-grid-sorting-index-spacing-x: -$kendo-grid-sorting-index-spacing-y !default;

$kendo-grid-sorted-icon-spacing: calc( #{$kendo-padding-md-x} - 1px ) !default;

$kendo-grid-focus-shadow: $kendo-table-focus-shadow !default;

$kendo-grid-command-cell-button-spacing: k-map-get( $kendo-spacing, 2 ) !default;
// be cautious when changing the next line; see https://github.com/MoOx/reduce-css-calc/issues/38
$kendo-grid-command-cell-padding-y: calc( #{$kendo-grid-cell-padding-y} - (#{$kendo-button-calc-size} - #{$kendo-line-height-em}) / 2 ) !default;

// Must be a solid color
$kendo-grid-sticky-bg: $kendo-grid-bg !default;
$kendo-grid-sticky-text: $kendo-grid-text !default;
$kendo-grid-sticky-border: if($kendo-enable-color-system, rgba( k-color( on-app-surface, true ), .5 ), rgba($kendo-grid-text, .5)) !default;

// Must be a solid color
$kendo-grid-sticky-alt-bg: if($kendo-enable-color-system, k-color( base-subtle ), k-try-shade( $kendo-grid-bg, .5 )) !default;

$kendo-grid-sticky-header-bg: $kendo-grid-header-bg !default;
$kendo-grid-sticky-header-text: $kendo-grid-header-text !default;
$kendo-grid-sticky-header-border: $kendo-grid-sticky-border !default;

$kendo-grid-sticky-footer-bg: $kendo-grid-header-bg !default;
$kendo-grid-sticky-footer-hover-bg: $kendo-grid-sticky-footer-bg !default;

$kendo-grid-sticky-selected-bg: if($kendo-enable-color-system, rgba( k-color( primary, true ), .25 ), k-color-mix( $kendo-selected-bg, $kendo-grid-bg, 25% )) !default;
$kendo-grid-sticky-selected-alt-bg: if($kendo-enable-color-system, rgba( k-color( primary, true ), .24 ), k-color-shade( $kendo-grid-sticky-selected-bg, .4 )) !default;

// Must be a solid color
$kendo-grid-sticky-hover-bg: if($kendo-enable-color-system, k-color( base-subtle-hover ), k-try-shade( $kendo-grid-bg )) !default;
$kendo-grid-sticky-selected-hover-bg: if($kendo-enable-color-system, rgba( k-color( primary, true ), .24 ), k-color-shade( $kendo-grid-sticky-selected-bg, .7 )) !default;

$kendo-grid-column-menu-width: 250px !default;
$kendo-grid-column-menu-max-width: 320px !default;

$kendo-grid-filter-menu-check-all-border-bottom-width: 1px !default;

$kendo-grid-filter-menu-item-spacing-x: k-map-get( $kendo-spacing, 6 ) !default;
$kendo-grid-filter-menu-item-spacing-y: k-map-get( $kendo-spacing, 2 ) !default;

$kendo-grid-column-menu-popup-padding-x: null !default;
$kendo-grid-column-menu-popup-padding-y: null !default;

$kendo-grid-column-menu-item-padding-x: $kendo-menu-popup-item-padding-x !default;
$kendo-grid-column-menu-item-padding-y: $kendo-menu-popup-item-padding-y !default;

$kendo-grid-column-menu-list-item-padding-x: $kendo-list-md-item-padding-x !default;
$kendo-grid-column-menu-list-item-padding-y: $kendo-list-md-item-padding-y !default;

$kendo-grid-column-menu-items-wrap-padding-x: 0 !default;
$kendo-grid-column-menu-items-wrap-padding-y: k-map-get( $kendo-spacing, 2 ) !default;

$kendo-grid-column-menu-group-header-padding-x: $kendo-menu-popup-md-item-padding-x !default;
$kendo-grid-column-menu-group-header-padding-y: $kendo-menu-popup-md-item-padding-y !default;

$kendo-grid-column-menu-group-header-font-size: $kendo-font-size-sm !default;
$kendo-grid-column-menu-group-header-line-height: $kendo-line-height-md !default;
$kendo-grid-column-menu-group-header-text-transform: uppercase !default;
$kendo-grid-column-menu-group-header-border-bottom-width: 1px !default;

$kendo-grid-column-menu-group-header-bg: null !default;
$kendo-grid-column-menu-group-header-text: $kendo-popup-text !default;
$kendo-grid-column-menu-group-header-border: $kendo-popup-border !default;

$kendo-grid-column-menu-tabbed-tabstrip-content-padding-x: 0 !default;
$kendo-grid-column-menu-tabbed-tabstrip-content-padding-y: k-map-get( $kendo-spacing, 2 ) !default;

$kendo-grid-column-menu-tabbed-bg: $kendo-base-bg !default;

$kendo-grid-drag-cell-width: ($kendo-icon-size * 3) !default;

$kendo-grid-row-inner-height: calc( #{$kendo-line-height-em} + #{$kendo-grid-cell-padding-y * 2} ) !default;

$kendo-grid-hierarchy-col-width: ($kendo-icon-size * 2) !default;

$kendo-grid-grouping-row-border-top: 1px !default;
$kendo-grid-group-footer-border-y: 1px !default;
$kendo-grid-group-footer-second-cell-border: 1px !default;

/// Background color of the grid row resize indicator
/// @group grid
$kendo-grid-row-resizer-hover-bg: if($kendo-enable-color-system, rgba( k-color( on-base, true ), .2 ), rgba( k-contrast-color( $kendo-grid-bg ), .12 )) !default;
/// Active background color of the grid row resize indicator
/// @group grid
$kendo-grid-row-resizer-active-bg: $kendo-color-primary !default;
/// Height of the grid row resize indicator
/// @group grid
$kendo-grid-row-resizer-height: k-map-get( $kendo-spacing, .5 ) !default;
